<script>
export default {
  name: "TNMDisplayPart",
  props: {
    part: String,
    data: Array
  },
  computed: {
    hasContent: function () {
      if(!this.part || !this.data) return false
      for(const tnm of this.data) {
        if(tnm.type_with_prefix === this.part) return true
      }
      return false
    }
  }
}
</script>

<template>
  <div v-if="hasContent">
  <h4 class="mt-3">{{part}}</h4>
  <table>
    <tbody v-for="tnm in data" :key="tnm.value">
    <tr v-if="tnm.type_with_prefix === part" class="border-bottom">
      <td style="width:50px; padding-top:5px; padding-bottom:5px;">
        {{tnm.value}}
      </td>
      <td>
        {{tnm.description}}
      </td>
    </tr>
    </tbody>
  </table>
  </div>
</template>

<style scoped>

</style>
